'use client';

import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import theme from './theme';

// 建立 cache 實例
const createEmotionCache = () => {
  return createCache({
    key: 'css',
    prepend: true, // 確保樣式被注入到 <head> 的開頭
  });
};

// 建立一個單例 cache
const emotionCache = createEmotionCache();

const ThemeProviders = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProviders;
